import restaurant from "./images/ruiloba.jpg";
import img_card from "./images/n.jpeg";
import img_nasi from "./images/nasi.jpeg";
import img_bucatar from "./images/bucatar.jpeg";
import img_music from "./images/formatia.jpeg";
import imgOmSuflet from "./images/14.jpg";
//import imgOmSufletmb from "./images/14.jpg";
import imgheader from "./images/img6.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";
import imgconfirmare from "./images/11.jpg";

{/*bazili1980@gmail.com*/}

const data = {
    introData: [{
        mire: "Vasile",
        mireasa: "Irina",
        title: "Invitatie Online",
        data: "16 Septiembre 2022",
        data_confirmare: "01.09.2022",
        savedata: "Te invitamos!!!",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "bazili1980@gmail.com", 
       tel: "+34 642 64 78 36",
       phone: "tel:+34642647836",
       viber: "viber://chat?number=%2B34642647836",
       whatsapp: "https://wa.me/+34642647836",
       messenger: "https://www.messenger.com/t/ctsw.tansportes",
       tel1: "+37368355031",
       phone1: "tel:+XXX",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+XXX",
       messenger1: "https://www.messenger.com/t/100008466997487",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Restaurante",
            name: "Ruiloba",
            data: "16 septiembre 2022, a las 19:30",
            adress: "C. Lopez Seña 26, 39770 Laredo Cantabria",
            harta: "https://goo.gl/maps/1gStwNPACezLSPCE6",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2898.361948601433!2d-3.417862199999999!3d43.4112639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4eea9e4d058387%3A0xa9f9d4dbf24413a7!2sXxxx!5e0!3m2!1ses!2s!4v1659104229331!5m2!1ses!2s"
           
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Invitación!",
            title2: "LES iNVITAMOS A LOS EVENTOS MAS IMPORTANTES EN NUESTRA VIDA!",
            message: "El día en que uniremos nuestros destinos, queremos estar rodeados de las personas más cercanas y queridas para nosotros. En este contexto, nos complace invitarlos a ser nuestros testigos y compartir la alegría del evento más significativo de nuestras vidas.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_nasi,
            title1: "Padrinos",
            title2: "Dorin & Victoria",
            message: "Los padrinos son lol únicos padres  que puedemos elegir.Y elegimos los mejores! A Vosotros!",
        }
    ],
    blogDataBucatar: [
        {
            id: 2,
            img: img_bucatar,
            title1: "Te invitamos con la mejor cocinera:",
            title2: "Natalia Căpătici Vizitiu",
            message: "Y saboreemos los deliciosos platos de manos de oro!",
        }
    ],
    blogDataMusic: [
        {
            id: 2,
            img: img_music,
            title1: "Bajo la dirección musical del dúo:",
            title2: "Verde Înrourat",
            message: "La diversión y el baile están asegurados hasta la mañana!",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "Gente conmovedora!",
            message: "Con quien escribiremos nuestra historia.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "\"El matrimonio es un arte que hay que crear todos los días.\"",
            message: " – R. Tagore",
        }
    ],
    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "No olvidar de traer buena disposición u ganas de bailar y disfrutar de todo espectáculo que lo vamos a poner!!!!",
            message: "P.D: Estaremos agradecidos por la confirmación de SU presencia!",
        }
    ], 

}

export default data;