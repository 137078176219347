import React, { Component } from "react";
import '../css/Header.css'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Language } from "@mui/icons-material";
import lang_ro from "../images/rom.jpg"
import lang_es from "../images/es.png"

export default class Header extends Component {
    constructor () {
        super();
        this.state={
           swow: true,
        };

    }
    render () {
        return (
        
<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
  <div className="container-fluid px-5">
   {/*<a className="navbar-brand "  href="#">{this.props.mire} & {this.props.mireasa}</a>*/ } 
    <Language/>
    <div className="mx-3">
    
    <a className="lang" href="https://vasile-irina.invitatii-web.md/"><img className="lang_img mx-1" src={lang_ro}/><b>ro</b></a> 
    </div>
    <div>
    
    <a className="lang" href="#"><img className="lang_img mx-1" src={lang_es}/><b>es</b></a> 
    </div>
   
    <button className="navbar-toggler border text-dark border-dark" 
    onClick={()=>{this.setState({show: !this.state.show})}}>
      {this.state.show ?  <CloseIcon/> : <MenuIcon/>}
    </button>
    
    <div className={this.state.show ? 'collapse navbar-collapse  active bg-white text-center'  : 'collapse navbar-collapse text-center'}>
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <a className="nav-link text-dark"  href="#blog">Invitación</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-dark"  href="#gallery">Galería</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-dark" href="#geolocal">Donde y Cuando?</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-dark" href="#forms">Confirmación</a>
        </li>
      </ul>
    </div>
  </div>
</nav> 
     
        )

 }}